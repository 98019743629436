<template>
  <v-row class="mx-0">
    <div class="card" style="margin-top:0;">
      <div class="card-header card-header-icon card-header-rose">
        <div class="card-icon">
            <i class="material-icons">sticky_note_2</i>
        </div>
        <h3 class="card-title">Folio Temporal</h3>
      </div>
      <div class="card-body">
        <v-row class="mx-0 my-0">
          <!--  Folios abiertos -->
          <v-col cols="9" class="text-center">
              <v-row>
                <v-col cols="6" v-for="parte in temporales" v-bind:key="parte.id">
                  <Ticket
                    v-bind:parte="parte" 
                    @clicked="onClickTicket"
                    @deleted="onClickDelete" />
                </v-col>
              </v-row>
          </v-col>
          <v-col cols="3" style="text-align:center;">
            <v-btn color="blue darken-1" class="py-2" :height="temporales.length > 0 ? 120:50" dark @click="checkDialog">
              <v-icon>mdi-plus</v-icon>Registrar Movimiento
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>    
    <div class="card" style="position:relative;">
      <div class="card-header card-header-icon card-header-rose">
        <div class="card-icon">
            <i class="material-icons">notes</i>
        </div>
        <h3 class="card-title">Consolidado Partes de Viaje</h3>
      </div>
      <div class="card-body">
        <v-data-table 
          :headers="partesListHeaders" 
          :items="partesListBody"
          fixed-header
          :height="table_height"
          :header-props="{ sortIcon: 'arrow_upward' }"
          :hide-default-footer="true"
          disable-pagination
        >
          <template v-slot:[`item.zarpe`]="{ item }">
            <span>{{ moment(item.zarpe).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.recalada`]="{ item }">
            <span v-if="item.recalada">{{ moment(item.recalada).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.atraco_fondeo`]="{ item }">
            <span v-if="item.atraco_fondeo">{{ moment(item.atraco_fondeo).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.inicio_faena`]="{ item }">
            <span v-if="item.inicio_faena">{{ moment(item.inicio_faena).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.termino_faena`]="{ item }">
            <span v-if="item.termino_faena">{{ moment(item.termino_faena).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.termino_cam`]="{ item }">
            <span v-if="item.termino_cam">{{ moment(item.termino_cam).format("DD-MM-YYYY HH:mm") }}</span>
          </template>
          <template v-slot:[`item.tiempo_faena`]="{ item }">
            <span v-if="item.tiempo_faena">{{ item.tiempo_faena.substring(0, 5) }}</span>
          </template>
          <template v-slot:[`item.tiempo_no_operativo`]="{ item }">
            <!--<span v-if="item.tiempo_no_operativo">{{ item.tiempo_no_operativo.substring(0, 5) }}</span>-->
            <span v-if="item.tiempo_no_operativo">{{ item.tiempo_no_operativo }}</span>
          </template>
          <template v-slot:[`item.horas`]="{ item }">
            <span v-if="item.horas">{{ item.horas.substring(0, 5) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editParte(item, false)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar parte</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on }">
                <v-icon
                  medium
                  @click="deleteParte(item)"
                  v-on="on"
                >
                mdi-delete
                </v-icon>
              </template>
              <span>Eliminar parte</span>
            </v-tooltip>
          </template>
        <!-- </v-data-table> -->
        </v-data-table>
      </div>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <!-- dialogo de movimientos -->
    <v-dialog
      v-model="showDialog"
      max-width="800"
    >
      <v-card class="my-2">
        <v-card-title>Indique tipo de movimiento</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-row class="mx-2">
            <v-col cols="4">
              <v-btn block color="primary" @click="showInterno" dense>Interno</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block color="primary" @click="showCosecha" dense>Cosecha</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block color="primary" @click="showSmolt" dense>Smolt</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block color="primary" @click="showMortalidad" dense>Mortalidad</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block color="primary" @click="showBano" dense>Baño</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn block color="primary" @click="showVacuna" dense>Vacuna</v-btn>
            </v-col>
            <!--<v-col cols="4">
              <v-btn block color="primary" @click="showMuestreo" dense :disabled="!habilitar_muestreo">Muestreo</v-btn>
              
            </v-col>-->
            <v-col cols="4">
              <v-btn block color="warning" @click="cancelar" dense>Cancelar</v-btn>
            </v-col>
          </v-row>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>
<style scoped>
  .card{
    margin-top: 0;
  }
  .card .card-header-rose .card-icon{
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  .v-dialog{
    width: 98%;
  }

  .table-responsive{
    width: 100%;
    max-height: 500px;
    overflow: auto;
  }
</style>
<script>
  import {mapState} from 'vuex'
  import Ticket from '@/components/Ticket.vue'

  export default {
    data: () => ({
      overlay: false,
      canEdit: false,
      canDelete: false,
      color: 'green',
      snackbar: false,
      showDialog: false,
      errors: [],
      validation_message: '',
      temporales: [],
      table_height: 500,
      page:1,
      last_page:null,
      partesListHeaders: [
        {
          text: 'Wellboat',
          align: 'center',
          sortable: true,
          value: 'wellboat.nombre',
           width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Tipo',
          align: 'center',
          sortable: true,
          value: 'tipoparte.nombre',
           width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Interno/ A C Cultivo / A C Acopio',
          align: 'center',
          sortable: true,
          //value: 'registro.nombre',
          value: 'sentidito.nombre',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Empresa',
          align: 'center',
          sortable: true,
          value: 'cliente.razon_social',
          width: "200px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Punto Origen',
          align: 'center',
          sortable: true,
          value: 'tipo_origen.nombre',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Origen',
          align: 'center',
          sortable: true,
          value: 'origen.nombre',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Zarpe',
          align: 'center',
          sortable: true,
          value: 'zarpe',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Recalada',
          align: 'center',
          sortable: true,
          value: 'recalada',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Atraco / Fondeo',
          align: 'center',
          sortable: true,
          value: 'atraco_fondeo',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Destino',
          align: 'center',
          sortable: true,
          value: 'destino.nombre',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Folio',
          align: 'center',
          sortable: true,
          value: 'folio',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Inicio Faena',
          align: 'center',
          sortable: true,
          value: 'inicio_faena',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Termino Faena',
          align: 'center',
          sortable: true,
          value: 'termino_faena',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Termino CAM',
          align: 'center',
          sortable: true,
          value: 'termino_cam',
          dataType: "Date",
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Tiempo Faena',
          align: 'center',
          sortable: true,
          value: 'tiempo_faena',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Tiempo No Operativo',
          align: 'center',
          sortable: true,
          value: 'tiempo_no_operativo',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Distancia (mn)',
          align: 'center',
          sortable: true,
          value: 'millas',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Horas Navegadas',
          align: 'center',
          sortable: true,
          value: 'horas',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Velocidad',
          align: 'center',
          sortable: true,
          value: 'nudos',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Observaciones',
          align: 'center',
          sortable: true,
          value: 'observaciones',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white--text"
        },
      ],
      partesListBody: [],
      habilitar_muestreo:null,
    }),
    mounted() {
      this.$store.commit('setParte', {});
      this.loadPartes();
      this.loadPartesTemporales();
      if(!this.canEdit && !this.canDelete){
        this.partesListHeaders.pop();
      }
      if(window.innerHeight < 768){
        this.table_height = 300;
      }

      document.querySelector('.v-data-table__wrapper').addEventListener('scroll',(event) => {
        
        //console.log(`scrollHeight: ${event.target.scrollHeight}, scrollTop: ${event.target.scrollTop}, clientHeight: ${event.target.clientHeight}`)
        if(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 1){
          if(this.last_page != null && this.page < this.last_page){
            this.page++;
            this.loadPartes()
          }
        }
        
      })

      this.habilitar_parte_muestreo()
    },
    methods:{
      onClickTicket(parte){
        this.$store.commit('setParte', parte)
        if(parte.tipopartes_id == 1){
          this.$router.push('/parte_cosecha')
          return
        }
        if(parte.tipopartes_id == 2){
          this.$router.push('/parte_interno')
          return
        }
        if(parte.tipopartes_id == 3){
          this.$router.push('/parte_smolt')
          return
        }
        if(parte.tipopartes_id == 4){
          this.$router.push('/parte_mortalidad')
          return
        }
        if(parte.tipopartes_id == 5){
          this.$router.push('/parte_bano')
          return
        }
        if(parte.tipopartes_id == 6){
          this.$router.push('/parte_vacuna')
          return
        }
        if(parte.tipopartes_id == 7){
          this.$router.push('/parte_muestreo')
          return
        }
        //this.previoParte();
      },
      async habilitar_parte_muestreo(){
        let url = `${this.base_url}habilitar_muestreo/wellboat/${this.user.wellboat.id}`;

        await this.axios.get(url, this.headers).then((response)=>{
          this.habilitar_muestreo = response.data.habilitar_muestreo
          console.log(response.data.habilitar_muestreo);

        }).catch((error)=>{
          console.log(error);
        });
      },
      onClickDelete(parte){
        this.deleteParte(parte);
      },
      checkDialog(){
        if(this.temporales.length == 0){
          this.showDialog = true;
        }else{
          this.validation_message = "Error. Hay ticket abiertos.";
          this.showSnackBar(true);
        }
      },
      showInterno(){
        this.$router.push('/parte_interno');
      },
      showCosecha(){
        this.$router.push('/parte_cosecha');
      },
      showSmolt(){
        this.$router.push('/parte_smolt');
      },
      showMortalidad(){
        this.$router.push('/parte_mortalidad');
      },
      showBano(){
        this.$router.push('/parte_bano');
      },
      showVacuna(){
        this.$router.push('/parte_vacuna');
      },
      showMuestreo(){
        this.$router.push('/parte_muestreo');
      },
      cancelar(){
        this.showDialog = false;
      },

      //mejora en carga de partes por tema de limite de execucion de script y memoria
      async loadPartes(){

        //fecha hasta 10 dias a fecha actual
        // let fecha = this.moment().subtract(10, 'days').format("YYYY-MM-DD")
        // console.log('10 dias antes',fecha)
        this.overlay = true;
        let url = `${this.base_url}partes?`;

        //fermin
        if(this.user.wellboat != undefined && this.user.wellboat.id > 0){
          url += `&wellboats_id=${this.user.wellboat.id}`;
        }
        url += `&limit=10&page=${this.page}`;
        //fermin

        //claudio
        // if(this.user.wellboat != undefined && this.user.wellboat.id > 0){
        //   url = `${this.base_url}partes/por_wellboat/${this.user.wellboat.id}`;
        // }
        //claudio

        await this.axios.get(url, this.headers).then((response)=>{
          //this.partesListBody = response.data;
          this.partesListBody = this.partesListBody.concat(response.data.data);
          this.last_page = response.data.last_page;
          this.overlay = false;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadPartesTemporales(){
        let url = `${this.base_url}partes/portipo/temporal`;
        if(this.user.wellboat != undefined && this.user.wellboat.id > 0){
          url = `${this.base_url}partes/temporales/wellboat/${this.user.wellboat.id}`;
        }
        await this.axios.get(url, this.headers).then((response)=>{
          this.temporales = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async deleteParte(item) {
        this.$confirm('Desea borrar este parte?').then(res => {
          if(res){
            let url = `${this.base_url}partes/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.temporales.indexOf(item);
              this.temporales.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
                this.validation_message = error;
                this.showSnackBar(true);
            });
          }
        });
      },
      async uploadPartes(){
        if(!this.file){
          this.validation_message = "Debe subir un archivo";
          this.showSnackBar(true);
          return;
        }
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}partes/upload`;
        let formData = new FormData();
        formData.append("excel", this.file);
        await this.axios.post(url, formData, this.headers).then((response)=>{
          console.log(response.data.data);
          this.file = null;
          this.upload = false;
          this.showSnackBar(false);
          this.loadPartes();
        }).catch((error)=>{
          console.log(error);
          this.validation_message = "Debe subir un archivo";
          this.showSnackBar(true);
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers', 'user']),
    },
    components:{
      Ticket
    }
  }
</script>