var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-0"},[_c('div',{staticClass:"card",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"card-header card-header-icon card-header-rose"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("sticky_note_2")])]),_c('h3',{staticClass:"card-title"},[_vm._v("Folio Temporal")])]),_c('div',{staticClass:"card-body"},[_c('v-row',{staticClass:"mx-0 my-0"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"9"}},[_c('v-row',_vm._l((_vm.temporales),function(parte){return _c('v-col',{key:parte.id,attrs:{"cols":"6"}},[_c('Ticket',{attrs:{"parte":parte},on:{"clicked":_vm.onClickTicket,"deleted":_vm.onClickDelete}})],1)}),1)],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"py-2",attrs:{"color":"blue darken-1","height":_vm.temporales.length > 0 ? 120:50,"dark":""},on:{"click":_vm.checkDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Registrar Movimiento ")],1)],1)],1)],1)]),_c('div',{staticClass:"card",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"card-header card-header-icon card-header-rose"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("notes")])]),_c('h3',{staticClass:"card-title"},[_vm._v("Consolidado Partes de Viaje")])]),_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.partesListHeaders,"items":_vm.partesListBody,"fixed-header":"","height":_vm.table_height,"header-props":{ sortIcon: 'arrow_upward' },"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.zarpe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.zarpe).format("DD-MM-YYYY HH:mm")))])]}},{key:"item.recalada",fn:function(ref){
var item = ref.item;
return [(item.recalada)?_c('span',[_vm._v(_vm._s(_vm.moment(item.recalada).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.atraco_fondeo",fn:function(ref){
var item = ref.item;
return [(item.atraco_fondeo)?_c('span',[_vm._v(_vm._s(_vm.moment(item.atraco_fondeo).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.inicio_faena",fn:function(ref){
var item = ref.item;
return [(item.inicio_faena)?_c('span',[_vm._v(_vm._s(_vm.moment(item.inicio_faena).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.termino_faena",fn:function(ref){
var item = ref.item;
return [(item.termino_faena)?_c('span',[_vm._v(_vm._s(_vm.moment(item.termino_faena).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.termino_cam",fn:function(ref){
var item = ref.item;
return [(item.termino_cam)?_c('span',[_vm._v(_vm._s(_vm.moment(item.termino_cam).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.tiempo_faena",fn:function(ref){
var item = ref.item;
return [(item.tiempo_faena)?_c('span',[_vm._v(_vm._s(item.tiempo_faena.substring(0, 5)))]):_vm._e()]}},{key:"item.tiempo_no_operativo",fn:function(ref){
var item = ref.item;
return [(item.tiempo_no_operativo)?_c('span',[_vm._v(_vm._s(item.tiempo_no_operativo))]):_vm._e()]}},{key:"item.horas",fn:function(ref){
var item = ref.item;
return [(item.horas)?_c('span',[_vm._v(_vm._s(item.horas.substring(0, 5)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editParte(item, false)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar parte")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""},on:{"click":function($event){return _vm.deleteParte(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar parte")])]):_vm._e()]}}],null,true)})],1)]),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")]),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"my-2"},[_c('v-card-title',[_vm._v("Indique tipo de movimiento")]),_c('v-card-text'),_c('v-card-actions',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showInterno}},[_vm._v("Interno")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showCosecha}},[_vm._v("Cosecha")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showSmolt}},[_vm._v("Smolt")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showMortalidad}},[_vm._v("Mortalidad")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showBano}},[_vm._v("Baño")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showVacuna}},[_vm._v("Vacuna")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"warning","dense":""},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }